import React from 'react'
import styled from 'styled-components'
import { graphql, HeadProps } from 'gatsby'
import { BlockRenderer, HeadTags, Footer, ArticleHeader, Masthead } from '@components'

import '@assets/stylesheets/reset.min.css'
import '@assets/stylesheets/main.scss'

interface IArticle {
  data: {
    sanityArticle: GatsbyTypes.SanityArticle
    allSanityArticle: {
      nodes: Array<GatsbyTypes.SanityArticle>
    }
  }
  pageContext: {
    settings: GatsbyTypes.SanitySiteSettings
  }
}

const ArticleTemplate = ({ data: { sanityArticle, allSanityArticle } }: IArticle) => {
  if (!sanityArticle) return <></>
  console.log(sanityArticle)
  return (
    <>
      <Masthead images={sanityArticle.stills?.map(still => still?.asset?.url || '') || []} />
      <Layout>
        <ArticleHeader>{sanityArticle.title}</ArticleHeader>
        <main>
          <ContentWrapper>
            <Content>
              <Origin>
                {!!sanityArticle.publication && (
                  <a href={sanityArticle.publication}>
                    originally published by{' '}
                    <em>
                      {sanityArticle.publication}, {sanityArticle.publishedAt}
                    </em>
                  </a>
                )}
                {!sanityArticle.publication && <>{sanityArticle.publishedAt}</>}
              </Origin>
              <BlockRenderer>{sanityArticle._rawCopy}</BlockRenderer>
            </Content>
          </ContentWrapper>
          <Footer />
        </main>
      </Layout>
    </>
  )
}

const Layout = styled.div`
  margin-top: calc(55vh - 75px);
  margin-top: calc(55dvh - 75px);
  @media only screen and (min-width: 744px) {
    margin-top: calc(56vh - 100px);
    margin-top: calc(56dvh - 100px);
  }
`

const Origin = styled.div`
  font-style: italic;
  font-size: 0.7em;
  margin-bottom: 1em;
`

const ContentWrapper = styled.div`
  position: relative;
  z-index: 10;
  padding: 0px 20px 20px 20px;
  min-height: calc(100vh - 112px);
  min-height: 100dvh;
  @media only screen and (min-width: 744px) {
    min-height: calc(100vh - 104px);
    padding: 0 40px 40px 40px;
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: rgba(15, 5, 0, 0.85);
  backdrop-filter: blur(50px);
`

const Content = styled.article`
  position: relative;
  z-index: 10;
  padding-bottom: 50px;
  @media only screen and (min-width: 744px) {
    width: 640px;
    margin: auto;
    padding-bottom: 100px;
  }

  h2 {
    font-size: 19px;
    @media only screen and (min-width: 744px) {
      font-size: 24px;
    }
    margin: 0.5em 0;
  }

  h3 {
    font-size: 17px;
    @media only screen and (min-width: 744px) {
      font-size: 22px;
    }
    margin: 0.5em 0;
  }

  p {
    font-size: 15px;
    @media only screen and (min-width: 744px) {
      font-size: 20px;
    }
  }

  li {
    list-style-type: '➣ ';
  }
`

export const query = graphql`
  query Article($slug: String!) {
    sanityArticle(slug: { current: { eq: $slug } }) {
      title
      source
      publication
      publishedAt
      slug {
        current
      }
      _rawCopy
      stills {
        asset {
          url
        }
      }
    }
  }
`

export const Head = (props: HeadProps) => <HeadTags {...props} />

export default ArticleTemplate
